import * as React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import PageHeader from "../../components/PageHeader";

const bgBlueGreen = `url(/img/bg-hero.png)`;


export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout scrollNavbar={true}>
        
       
        <div className="pt-20">
          <PageHeader title="Latest News & Resources" byline="STEPWISE BLOG"></PageHeader>
          <div className="pt-12 pb-24 bg-gray-100 px-3">
            <div className="max-w-6xl mx-auto">
            <BlogRoll postCount={24} />
            </div>

          </div>
        </div>
       
      </Layout>
    );
  }
}
